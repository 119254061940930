<template>
    <nuxt-icon :name="name" :class="classNames" />
</template>
<script setup lang="ts">
import { twMerge, twJoin } from 'tailwind-merge'

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    class: {
        type: String
    }
});

const classNames = twMerge(twJoin('inline-flex text-current w-9 h-9', toRef(props, 'class').value));
</script>

<style>
.nuxt-icon svg {
    width: initial;
    height: initial;
}
</style>
